import axios from 'axios';
// import BaseUrl from '../Util/BaseUrl';

// const API_URL = BaseUrl.baseUrl+"api/blog";

const API_URL = 'https://api.blogfortis.com/api/blog/'
const web = 'cfcb2d8a-2504-4302-835a-5efdd82d2029';

const getAllBlogs = async (page = 0, size = 10) => {
    try {
        const response = await axios.get(API_URL+'web', {
            params: { page, size , web}
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to fetch blogs');
    }
};

const getBlogById = async (blog) => {
    try {
        const response = await axios.get(API_URL+'detail', {
            params: { web, blog}
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to fetch blog');
    }
};

// const getRandomBlogs = async (size = 6) => {
//     try {
//         const response = await axios.get(`${API_URL}/random/${size}`);
//         return response.data;
//     } catch (error) {
//         throw new Error(error.response ? error.response.data : 'Failed to fetch blogs');
//     }
// };


const BlogService = {
    getAllBlogs,
    getBlogById
};

export default BlogService;