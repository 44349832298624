import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlogService from "../service/BlogService";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";
import { Alert, Spinner } from "react-bootstrap";

const BlogPage = () =>{

    const { blogId } = useParams(); // Get the blog ID from the URL
    const [blog, setBlog] = useState(null); // State to store the blog data
    const [loading, setLoading] = useState(true); // Loading state for fetching data
    const [error, setError] = useState(null);
    const [relatedBlogs, setRelatedBlogs] = useState([]);

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await BlogService.getBlogById(blogId); // Fetch the blog by ID
                setBlog(response); // Set the blog data
            } catch (error) {
                console.error('Error fetching Blogs:', error);
                setError("Sorry! this Blog is currently not available, please find other related Blogs")
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchBlog();
        fetchRandomBlog();
        window.scrollTo(0, 0);
    }, [blogId]);

    const sanitizedContent = blog ? DOMPurify.sanitize(blog.content) : '';

    const fetchRandomBlog = async () => {
        try {
            const response = await BlogService.getAllBlogs(0, 4);            
            setRelatedBlogs(response.content); // Set the blog data
        } catch (error) {
            console.error('Error fetching Blogs:', error);
        }
    };

    const createSlug = (title) => {
        if (!title) {
            return null;
        }

        return title
            .toLowerCase() // Convert to lowercase
            .trim() // Trim leading/trailing spaces
            .replace(/\s+/g, '-') // Replace spaces with hyphens
            .replace(/[^\w\-]+/g, '') // Remove all non-word characters
            .replace(/--+/g, '-') // Replace multiple hyphens with a single hyphen
            .replace(/^-+|-+$/g, ''); // Remove leading/trailing hyphens
    };

    return(
        <>
        {!loading && !error && blog && (<section className="breadcrumb_section detail_breadcrumb">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Blogs</li>
                                <li className="breadcrumb-item active" aria-current="page">{blog?.title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        )}

        <Helmet>
            <link rel="canonical" href={`/blog/${blogId}/${createSlug(blog?.title)}`}/>
            <title>{blog?.metaTitle}</title>
            <meta name="description" content={blog?.metaDescription} />
            <meta name="keywords" content={blog?.keywords} />

            <meta property="og:title" content={blog?.metaTitle} />
            <meta property="og:description" content={blog?.metaDescription} />
        </Helmet>

        <section className="blog_detail_content">
        {loading && (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading, Please wait...</span>
                </Spinner>
            </div>
        )}

        {error && (
            <div className="blog_error_alert w-50 mx-auto my-md-5 py-md-5 my-2 py-2">
            <Alert variant="danger" className="my-md-4 my-0">
                {error} <a href="/blogs">Click Here...</a>
            </Alert>
            </div>
        )}

        {!loading && !error && blog && (<>
        <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-8">
              <div className="blog_detail_main text-left">
              <h1>{blog.title}</h1>
                <span className="blog_detail_date">
                <i className="far fa-calendar" title="date"></i>
                {new Date(blog.publishedDate).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit'
                })}
                </span>

              {blog.coverImageUrl && ( // Display the featured image if it exists
              <img className="img-fluid" src={blog.coverImageUrl} alt={blog.coverImageUrl} />
              )}
            <div className="blog-content-area" dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
            </div>
            </div>

            {relatedBlogs.length > 0 && (
                <div className="col-md-4 pl-md-5">
                    <div className="blog_detail_sidebar">
                        <h3>Latest Blogs</h3>
                        <ul className="list-unstyled text-left">
                            {relatedBlogs.map((blog) => (
                                <li key={blog.id}>
                                    <a href={`/blog/${blog.id}/${createSlug(blog.title)}`} className="text-decoration-none">
                                        {blog.title}
                                    </a>
                                    <p className="small"><i className="far fa-calendar" title="date"></i> {new Date(blog.publishedDate).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit'
                                    })}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}

            </div>
        </div>
        </>
         )}
        </section>

        </>
    );
};

export default BlogPage;