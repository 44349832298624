import React from 'react';
import AboutUs from '../component/aboutUs/AboutUs';
import { Helmet } from 'react-helmet';
import ComponenetHeader from '../component/common/ComponenetHeader';

const AboutPage = () => {

  return (
    <>
      <Helmet>
        <link rel="canonical" href="/about"/>
        <title>Reliable Tow Truck Service Near You - Fast, Affordable, and Professional</title>
        <meta name="keywords" content="semi truck repair"/>
        <meta name="description" content="Contact us today for immediate assistance or to schedule a service! Experience the best tow truck service near you - where your satisfaction is our top priority!" />
        <meta property="og:title" content="Reliable Tow Truck Service Near You - Fast, Affordable, and Professional" />
        <meta property="og:description" content="Contact us today for immediate assistance or to schedule a service! Experience the best tow truck service near you - where your satisfaction is our top priority!" />
      </Helmet>
      <ComponenetHeader
        name={'About Us'}
      />
      <AboutUs />
    </>
  );
};

export default AboutPage;