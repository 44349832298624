import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col, InputGroup, Dropdown, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SubcategoryService from '../../service/SubcategoryService';
import LocationService from '../../service/LocationService';
import icon1 from '../../assets/icon-one.webp';
import icon2 from '../../assets/icon-two.webp';
import icon3 from '../../assets/icon-three.webp';
import icon4 from '../../assets/icon-four.webp';
import icon5 from '../../assets/icon-five.webp';
import icon6 from '../../assets/icon-six.webp';
import brand1 from '../../assets/brand-1.webp';
import brand2 from '../../assets/brand-2.webp';
import brand3 from '../../assets/brand-3.webp';
import brand4 from '../../assets/brand-4.webp';
import brand5 from '../../assets/brand-5.webp';
import brand6 from '../../assets/brand-6.webp';

const SearchSection = () => {
  const [location, setLocation] = useState('');
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [showLocationSuggestions, setShowLocationSuggestions] = useState(false);
  const [showSubcategory, setShowSubcategory] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const subcategoriesResponse = await SubcategoryService.getSubcategoriesByCategory();
      setSubcategories(subcategoriesResponse);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const handleLocationChange = async (e) => {
    const newLocation = e.target.value;
    setLocation(newLocation);

    if (newLocation.length >= 3) {
      setLoading(true);
      setShowLocationSuggestions(true);
      try {
        const response = await LocationService.getLocationByName(newLocation);
        setLocationSuggestions(response);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching location suggestions:', error);
        setLoading(false);
        setShowLocationSuggestions(false);
      }
    } else {
      setShowLocationSuggestions(false);
      setLoading(false);
    }
  };

  const handleLocationSelect = (selectedLocation) => {
    setLocation(selectedLocation.location); // For display in the input field
    setShowLocationSuggestions(false);
  };

  const handleSearch = () => {
    const selectedSubcategoryData = subcategories.find(sub => sub.name === selectedSubcategory);
    if (selectedSubcategoryData) {
      sessionStorage.setItem("subcategoryId", selectedSubcategoryData.id);
    }

    const formattedSubcategory = selectedSubcategory.toString().trim().replace(/\s+/g, '-');
    const searchlocation = location.trim().replace(/,\s*/g, '-');
    sessionStorage.removeItem("shopType");

    const url = `/service-near-me/${formattedSubcategory}/${searchlocation}`;
    navigate(url);
  };

  const handleLinks = (subcategory) => {
    const selectedSubcategoryData = subcategories.find(sub => sub.name === subcategory);
    if (selectedSubcategoryData) {
      sessionStorage.setItem("subcategoryId", selectedSubcategoryData.id);
    }

    const formattedSubcategory = (selectedSubcategoryData?.name).toString().trim().replace(/\s+/g, '-');
    sessionStorage.removeItem("shopType");

    const searchlocation = ['Phoenix-AZ-USA', 'Fresno-CA-USA', 'Dallas-TX-USA', 'Washington-DC-USA', 'Beaumont-CA-USA', 'Dallas-GA-USA',]
    const randomlocation = searchlocation[Math.floor(Math.random() * searchlocation.length)];

    const url = `/service-near-me/${formattedSubcategory}/${randomlocation}`;
    navigate(url);
  };

  const handleSubcategorySelect = (subcategory) => {
    setSelectedSubcategory(subcategory);
    setShowSubcategory(false);
  };

  return (<>
    <section className="home_search">
      <Container>
        <Row className="justify-content-center">
          <Col md={10} sm={12} className='px-md-0'>
            <h1 className="text-center">Find Truck Repair <br/>Services Near You</h1>
          </Col>
        </Row>
        <Form onSubmit={handleSearch}>
          <Row className="align-items-center justify-content-center search_input">
            <Col md={3} sm={12} className="category_search">
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Choose your option"
                  value={selectedSubcategory}
                  onClick={() => setShowSubcategory(!showSubcategory)}
                  readOnly
                />
              </InputGroup>
              {showSubcategory && (
                <Dropdown.Menu show className="w-100">
                  {subcategories.map((subcategory) => (
                    <Dropdown.Item
                      key={subcategory.id}
                      onClick={() => handleSubcategorySelect(subcategory.name)}
                    >
                      {subcategory.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              )}
            </Col>
            <Col md={3} sm={12} className="location_search">
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Enter your city, state"
                  value={location}
                  onChange={handleLocationChange}
                  className="form-control"
                />
                <Button
                  variant="outline-secondary"
                //onClick={handleLocation}
                >
                  <i className="fas fa-location-dot" title="location"></i>
                </Button>
              </InputGroup>
              {showLocationSuggestions && (
                <Dropdown.Menu show className="w-100">
                  {loading ? (
                    <div className="spinner-container d-flex justify-content-center mt-4">
                      <Spinner animation="border" role="status" size="m" />
                    </div>) : (
                    <>
                      {locationSuggestions.length > 0 ? (
                        locationSuggestions.map((suggestion, index) => (
                          <Dropdown.Item key={index} onClick={() => handleLocationSelect(suggestion)}>
                            {suggestion.location}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <Dropdown.Item disabled>No results found</Dropdown.Item>
                      )}
                    </>
                  )}
                </Dropdown.Menu>
              )}
            </Col>
            <Col md={2} sm={12} className="search_btn">
              <Button
                variant="primary"
                className="btn-block"
                onClick={handleSearch}
              >
                <i className="fa fa-search" title="search"></i>  Search
              </Button>
            </Col>
          </Row>
        </Form>
        <Row className="justify-content-center searchIcon_section">
          <Col className='search_icon mt-5 px-0' md={12} sm={12}>
            <ul>
              <li> <a onClick={() => handleLinks("Truck Repair")}><img src={icon1} className='img-fluid' alt='Truck Repair'/><span>Truck Repair</span></a></li>
              <li> <a onClick={() => handleLinks("Trailer Repair")}><img src={icon2} className='img-fluid' alt='Trailer Repair'/><span>Trailer Repair</span></a></li>
              <li> <a onClick={() => handleLinks("Tire Repair and Sales")}><img src={icon3} className='img-fluid' alt='Tire Repair'/><span>Tire Repair</span></a></li>
              <li> <a onClick={() => handleLinks("Towing and Recovery")}><img src={icon4} className='img-fluid' alt='Towing Service'/><span>Towing Service</span></a></li>
              <li> <a onClick={() => handleLinks("Reefer Repair")}><img src={icon5} className='img-fluid' alt='Reefer Repair'/><span>Reefer Repair</span></a></li>
              <li> <a href="#" ><img src={icon6} className='img-fluid' alt='Driving School'/><span>Driving School</span></a></li>
            </ul>
          </Col>
        </Row>


      </Container>
    </section>

    <section className='brand_section'>
      <Container>
        <Row className="justify-content-center">
          <Col className='search_icon px-0' md={12} sm={12}>
            <ul>
              <li><img src={brand1} className='img-fluid' alt='Loves Truck Tire'/></li>
              <li><img src={brand2} className='img-fluid' alt='Good Year'/></li>
              <li><img src={brand3} className='img-fluid' alt='Pilot Flying Truck Care'/></li>
              <li><img src={brand4} className='img-fluid' alt='TA Truck Service'/></li>
              <li><img src={brand5} className='img-fluid' alt='NAPA'/></li>
              <li><img src={brand6} className='img-fluid' alt='Speedco'/></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>

  </>
  );
};

export default SearchSection;
