import React from 'react';
import Plans from '../component/plan/Plans';
import ComponenetHeader from '../component/common/ComponenetHeader';
import PlanImg1 from '../assets/plan_subscription.webp';
import { Helmet } from 'react-helmet';

const PlanPage = () => {
  return (
    <>
    <ComponenetHeader
      name={'Plans'}
    />

      <Helmet>
        <link rel="canonical" href="/plans"/>
        <title>Truck & Trailer Repair | 24/7 Roadside Assistance & Service</title>
        <meta name="keywords" content="tow truck service near me, tire repair service"/>
        <meta name="description" content="Get quick, truck and trailer repair services right at your location. Contact us today for on-site solutions that get you back on the road fast! Breakdown Inc"/>
        <meta property="og:title" content="Truck & Trailer Repair | 24/7 Roadside Assistance & Service"/>
        <meta property="og:description" content="Get quick, truck and trailer repair services right at your location. Contact us today for on-site solutions that get you back on the road fast! Breakdown Inc"/>
      </Helmet>

    <section className='new_plan-section'>
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
            <div className='col-md-6'>
              <div className='plan_new_img'>
              <img src={PlanImg1} className='img-fluid' alt='Plan Subscription' />
              </div>
            </div>

            <div className='col-md-5'>
            <div className="section-title mb-2 text-left">
              <p className='mb-2'>- PRICING INFO -</p>
              <h1 className='mb-2 h1_title'>Flexible Subscription Plans </h1>
            </div>
            <div className='plan_new_content text-left'>
                <p>BreakDown Inc is your trusted partner for enhancing visibility and driving growth in the trucking
                and transportation industry. Our advertising solutions are designed to connect service providers
                with their target audience effectively. With BreakDown Inc, your business gains access to a
                platform built for results, ensuring your services stand out where it matters most.</p>
                <p>Explore our platform risk-free with our complimentary Bronze plan, featuring all the essential
                  tools to showcase your business. Upgrade at your convenience to unlock advanced features and expand
                  your reach further. BreakDown Inc – where your success takes the spotlight.</p>
            </div>
          </div>

        </div>
      </div>
    </section>



    <Plans/>

    <section className='new_plan-table'>
      <div className='container'>
        <div className='row justify-content-center'>
        <div className='col-md-11'>

        <div className="section-title mb-md-5 mb-4">
              <p>- CHOOSE THE BEST -</p>
              <h2>Discover Your Perfect Plan </h2>
        </div>

        <div className='plan_table table-responsive'>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Feature</th>
              <th scope="col">Bronze</th>
              <th scope="col">Silver</th>
              <th scope="col">Gold</th>
              <th scope="col">Diamond</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Image Display</th>
              <td><i className="fas fa-times"></i></td>
              <td><b>SMALL</b> <span className='small'>(550X250px)</span></td>
              <td><b>MEDIUM</b> <span className='small'>(550X400px)</span></td>
              <td><b>LARGE</b> <span className='small'>(550X600px)</span></td>
            </tr>
            <tr>
              <th scope="row">Distance Visibility</th>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
            </tr>
            <tr>
              <th scope="row">Company Name</th>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
            </tr>
            <tr>
              <th scope="row">Business Address</th>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
            </tr>
            <tr>
              <th scope="row">Contact Email</th>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
            </tr>
            <tr>
              <th scope="row">Contact Phone</th>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
            </tr>
            <tr>
              <th scope="row">Services Display</th>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
              <td><i className="fa fa-check"></i></td>
            </tr>
            <tr>
              <th scope="row">Listing Highlights</th>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fas fa-times"></i></td>
              <td><i className="fa fa-check"></i></td>
            </tr>
          </tbody>
        </table>
        </div>


        </div>
        </div>
      </div>
    </section>



    </>
  );
};

export default PlanPage;
