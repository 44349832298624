import React from "react";
import Plans from "../component/plan/Plans";
import SearchSection from "../component/search/SearchSection";
import AboutUs from "../component/aboutUs/AboutUs";
import { Helmet } from "react-helmet";

const HomePage =()=>{

    return(
        <div className="main_content">
            <Helmet>
                <link rel="canonical" href="/"/>
                <title>Reliable 24/7 Truck Repair Services & Roadside Assistance</title>
                <meta name="keywords" content="Truck Repair Services ,truck repair service near me"/>
                <meta name="description" content="Find trusted truck repair services, trailer repair services, and reefer repair solutions near you with Breakdown Inc. Get trucking solutions anytime. Call now" />
                <meta property="og:title" content="Reliable 24/7 Truck Repair Services & Roadside Assistance" />
                <meta property="og:description"
                    content="Find trusted truck repair services, trailer repair services, and reefer repair solutions near you with Breakdown Inc. Get trucking solutions anytime. Call now" />
            </Helmet>

            <SearchSection/>
            <AboutUs/>
            <Plans/>
            <section className="about_section-2">
          <div className="container">
            <div className="row justify-content-center">
                    <div className="section-title col-md-12 mb-4">
                       <p className="text-white font-weight-normal">- Get the Road Service -</p>
                       <h2 className="text-white">How Can We Help You?</h2>
                    </div>

                    <div className="col-md-4 mb-3">
                    <div className="about_service h-100">
                    <h2>Truck Repair Services</h2>
                    <p>Truck breakdowns are unpredictable, can occur anytime, anywhere. You can stay prepared beforehand and find truck repair services near you with the help of the Breakdown Inc app. Your one stop source to find affordable and quality truck repair services.</p>
                    </div>
                    </div>

                    <div className="col-md-4 mb-3">
                    <div className="about_service h-100">
                    <h2>Trailer Repair Services</h2>
                    <p>Require trailer repair service in an unknown location? Don't worry, open Breakdown Inc. mobile app and find the nearest trailer repair service providers in seconds. Contact the service provider which suits you the best, get service done & be back on the road in no time.</p>
                    </div>
                    </div>

                    <div className="col-md-4 mb-3">
                    <div className="about_service h-100">
                    <h2>Towing & Recovery Services</h2>
                    <p>You can easily find your nearest towing and recovery service providers on Breakdown Inc. mobile app, whether you are in need of heavy duty vehicle towing or light duty vehicle towing. We take pride in providing you with information that you can rely on.</p>
                    </div>
                    </div>

                    <div className="col-md-4 mb-3">
                    <div className="about_service h-100">
                    <h2>Reefer Repair Services</h2>
                    <p>Reefer repair is a relatively rare service, and finding competent technicians can be challenging. But with Breakdown Inc. app, you can easily locate reefer repair service providers near you. You can also find 24/7 Reefer repair services on the Breakdown Inc app.</p>
                    </div>
                    </div>

                    <div className="col-md-4 mb-3">
                    <div className="about_service h-100">
                    <h2>Emergency Roadside Assistance</h2>
                    <p>An emergency can strike at any time, and it is undoubtedly quite stressful. We recognize the importance of such situations, that's why we only provide you with precise and dependable information on emergency roadside assistance providers near you.</p>
                    </div>
                    </div>

                    <div className="col-md-4 mb-3">
                    <div className="about_service h-100">
                    <h2>Tire Repair Shops</h2>
                    <p>Tire problems are the most common causes of breakdowns. Under-inflation, misaligned axles, and low tread depth are the most common causes of tire failure. Whatever the situation may be, you can find the tire repair shops near you on Breakdown Inc mobile app.</p>
                    </div>
                    </div>

         </div>
         </div>
        </section>
        </div>
    );
};

export default HomePage;